
import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class Auth extends Vue {
  year: any

  created() {
    let data = new Date()
    this.year = data.getFullYear()
  }
}
